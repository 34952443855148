export const videos = [
  {
    name: "AntsLive - Captain Ants",
    video:
      "https://d1b8cx5vpl3pye.cloudfront.net/video/Captain_Ants.mp4",
    link: "https://vimeo.com/871482036/30081ea79e",
  },
  {
    name: "AntsLive - Number One Candidate",
    video:
      "https://d1b8cx5vpl3pye.cloudfront.net/video/number_one_candidate.mp4",
    link: "https://vimeo.com/788654915",
  },
  {
    name: "Wildfarmed x Supa Ya Ramen",
    video:
      "https://d1b8cx5vpl3pye.cloudfront.net/video/wildfarmed_supa_ya_ramen.mp4",
    link: "https://vimeo.com/730389767",
  },
  {
    name: "AntsLive - Ooh La La",
    video:
      "https://d1b8cx5vpl3pye.cloudfront.net/video/Ooh_La_La.mp4",
    link: "https://vimeo.com/868910837/b878455d7d",
  },
  {
    name: "AntsLive - Tweakin",
    video:
      "https://d1b8cx5vpl3pye.cloudfront.net/video/tweakin_teaser_v2.mp4",
    link: "https://vimeo.com/729562444",
  },
  {
    name: "A$AP Nast x Reebok",
    video:
      "https://d1b8cx5vpl3pye.cloudfront.net/video/ASAP_NAST_x_Reebok_for_website.mp4",
    link: "https://vimeo.com/729692180",
  }, // UPDATE
  {
    name: "Mimi Webb - Before I Go",
    video:
      "https://d1b8cx5vpl3pye.cloudfront.net/video/Before_I_Go_webiste.mp4",
    link: "https://vimeo.com/732148175",
  },
  {
    name: "Col3trane - Logan Paul",
    video:
      "https://d1b8cx5vpl3pye.cloudfront.net/video/logan_paul_video_website.mp4",
    link: "https://vimeo.com/730633659",
  },
  {
    name: "Col3trane feat. Mahalia - Pretty",
    video:
      "https://d1b8cx5vpl3pye.cloudfront.net/video/coletrane_compressed_muted.mp4",
    link: "https://vimeo.com/732285474",
  }, // UPDATE
];
