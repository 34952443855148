import { useEffect, useState } from "react";

import "keen-slider/keen-slider.css";
import { useKeenSlider } from "keen-slider/react";
import { IconButton, Tab, Tabs } from "@mui/material";

import styled from "@emotion/styled";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const query = {
  "1_A$AP NAST x Reebok": {
    0: "1.png",
    1: "2.png",
    2: "3.png",
    3: "4.png",
  },
  "2_Fila x TooHot": {
    0: "1.png",
    1: "2.png",
    2: "3.png",
    3: "4.png",
  },
  "3_i-D t-shirts": {
    0: "1.png",
    1: "2.png",
    2: "3.png",
  },
  "4_Helene Studios": {
    0: "3.png",
    1: "4.png",
    2: "5.png",
  },
  "5_Gosha Rubchinsky x Super Sunglasses": {
    0: "1.png",
    1: "2.png",
    2: "3.png",
    3: "4.png",
  },
  "6_Recens Paper": {
    0: "1.png",
    1: "2.png",
    2: "3.png",
  },
  "7_Helmut Lang": {
    0: "1.png",
    1: "2.png",
    2: "3.png",
  },
  "8_Burberry": {
    0: "1.png",
    1: "3.png",
    2: "4.png",
  },
  "9_Axel Arigato": {
    0: "2.png",
    1: "3.png",
    2: "4.png",
  },
  "10_Levi_s": {
    0: "1.png",
    1: "2.png",
    2: "3.png",
    3: "4.png",
  },
  "12_Folk x Mr Porter": {
    0: "1.png",
    1: "2.png",
    2: "3.png",
    3: "4.png",
    4: "5.png",
  },
  "13_Converse x Size_": {
    0: "1.png",
    1: "2.png",
    2: "3.png",
  },
  "14_i-D - Skate Kitchen": {
    0: "1.png",
    1: "2.png",
    2: "3.png",
    3: "4.png",
  },
  "15_i-D x Atihan Akec": {
    0: "1.png",
    1: "2.png",
  },
  "16_Champion": {
    0: "2.png",
    1: "3.png",
    2: "4.png",
  },
  "17_Sarkodie": {
    0: "1.png",
    1: "2.png",
  },
  "19_AntsLive - Tweakin": {
    0: "1.png",
  },
  "20_Col3trane - Take Notes": {
    0: "1.png",
  },
  "21_Hypebeast": {
    0: "1.png",
    1: "2.png",
    2: "3.png",
    3: "4.png",
  },
  "22_Jeshi Press Photos": {
    0: "1.png",
    1: "2.png",
  },
  "23_Renao Press Photos": {
    0: "1.png",
    1: "2.png",
    2: "3.png",
  },
  "24_TooHot": {
    0: "1.png",
    1: "2.png",
    2: "3.png",
    3: "4.png",
  },
  "25_Personal": {
    0: "13.png",
    1: "17.png",
    2: "19.png",
    3: "23.png",
    4: "24.png",
    5: "25.png",
    6: "26.png",
    7: "28.png",
    8: "29.png",
    9: "31.png",
    10: "35.png",
    11: "36.png",
    12: "39.png",
    13: "40.png",
    14: "41.png",
    15: "42.png",
    16: "43.png",
    17: "44.png",
  },
};

const images = Object.keys(query)
  .map((a) =>
    Object.keys(query[a]).map((b) => ({
      url: `https://d1b8cx5vpl3pye.cloudfront.net/photo/${a}/${query[a][b]}`,
      shoot: a,
    }))
  )
  .flatMap((a) => a);

const CustomTabs = styled(Tabs)({
  flex: 1,
  minHeight: 28,
});

const CustomTab = styled(Tab)(({ theme }) => ({
  color: "#9A9A9A",
  fontSize: "1em",
  [theme.breakpoints.down("md")]: {
    fontSize: "0.8em",
    lineHeight: "inherit",
    minHeight: 26,
  },
  textTransform: "none",
  minHeight: "8px",
  lineHeight: "16px",
}));

const ContainerDiv = styled("div")(({ theme }) => ({
  display: "flex",
  backgroundColor: "rgba(0,0,0,0.8)",
  top: 50,
  [theme.breakpoints.down("md")]: {
    top: 50,
  },
  zIndex: 5,
  width: "100%",
  position: "fixed",
}));

const ContentTabs = ({ selected, slideToFirstOfShoot }) => {
  const [currentTab, setCurrentTab] = useState(selected);

  const handleChange = (_event, newValue) => {
    setCurrentTab(newValue);
    slideToFirstOfShoot(newValue);
  };

  useEffect(() => setCurrentTab(selected), [selected]);

  const tabs = [...new Set(images.map(({ shoot }) => shoot))];

  return (
    <ContainerDiv>
      <IconButton
        onClick={() => {
          setCurrentTab(tabs[tabs.findIndex((a) => a === currentTab) - 1]);
          slideToFirstOfShoot(
            tabs[tabs.findIndex((a) => a === currentTab) - 1]
          );
        }}
        disabled={tabs.findIndex((a) => a === currentTab) === 0}
      >
        <ArrowBackIosIcon />
      </IconButton>
      <CustomTabs
        value={currentTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={false}
      >
        {tabs.map((a) => (
          <CustomTab value={a} label={a.split("_")[1]} wrapped />
        ))}
      </CustomTabs>
      <IconButton
        onClick={() => {
          setCurrentTab(tabs[tabs.findIndex((a) => a === currentTab) + 1]);
          slideToFirstOfShoot(
            tabs[tabs.findIndex((a) => a === currentTab) + 1]
          );
        }}
        disabled={tabs.findIndex((a) => a === currentTab) === tabs.length - 1}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </ContainerDiv>
  );
};

const KeenSlider = styled("div")(({ theme }) => ({
  maxWidth: "fit-content",
  width: "auto",
}));

const KeenSliderPhoto = styled("img")(({ theme }) => ({
  "@keyframes animateBg": {
    "0%": { backgroundPosition: "100% 0%" },
    "100%": { backgroundPosition: "0% 0%" },
  },
  animation: "animateBg 1s linear infinite",
  backgroundImage: "linear-gradient(90deg,#000000,#424242,#000000,#424242)",
  backgroundSize: "300% 100%",
  "&:after": {
    height: "100vh",
  },
  minHeight: 100,
}));

const AnimatedDiv = styled("div")(({ theme }) => ({
  "@keyframes animateBg": {
    "0%": { backgroundPosition: "100% 0%" },
    "100%": { backgroundPosition: "0% 0%" },
  },
  animation: "animateBg 1s linear infinite",
  backgroundImage: "linear-gradient(90deg,#000000,#424242,#000000,#424242)",
  backgroundSize: "300% 100%",
}));

const SurrounderDiv = styled("div")(({ theme }) => ({
  width: "100%",
  marginTop: 110,
  [theme.breakpoints.down("md")]: {
    marginTop: 95,
  },
}));

const PhotoContent = () => {
  const [options, setOptions] = useState({});
  const [currentSlide, setCurrentSlide] = useState(0);
  const [refCallback, slider] = useKeenSlider(options);

  const slideToFirstOfShoot = (shoot) => {
    const index = images.findIndex((item) => item.shoot === shoot);
    slider.current.moveToIdx(index);
  };

  useEffect(() => {
    setTimeout(() => {
      setOptions({
        slideChanged: (element) => {
          setCurrentSlide(element.track.details.rel);
        },
        animationEnded: () => {
          slider && slider.current.update();
        },
        mode: "snap",
        slides: { perView: "auto" },
        defaultAnimation: {
          duration: 1000,
        },
        renderMode: "performance",
      });
    }, 500);
  }, [slider]);

  return (
    <SurrounderDiv>
      <ContentTabs
        selected={images[currentSlide].shoot}
        slideToFirstOfShoot={slideToFirstOfShoot}
      />
      <KeenSlider
        ref={refCallback}
        className="keen-slider"
        style={{ position: "fixed", height: "85%" }}
      >
        {images.map((image, idx) => (
          <div
            className="keen-slider__slide"
            style={{
              overflow: "visible",
              maxWidth: "100vw",
              display: "flex",
              width: "auto",
            }}
            key={idx}
          >
            {Math.abs(idx - currentSlide) <= 6 ? ( // render previous and next 6 images
              <KeenSliderPhoto
                alt="test"
                loading="eager"
                src={image.url}
                style={{
                  alignSelf: "center",
                  maxWidth: 500,
                  width: "100vw",
                }}
              />
            ) : (
              <AnimatedDiv
                style={{
                  alignSelf: "center",
                  maxWidth: 500,
                  width: "100vw",
                }}
              />
            )}
          </div>
        ))}
      </KeenSlider>
      <IconButton
        onClick={() => slider.current.prev()}
        style={{ position: "absolute", zIndex: 3, top: "50%" }}
      >
        <ArrowBackIosIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          slider.current.next();
        }}
        style={{ position: "absolute", zIndex: 3, top: "50%", right: 0 }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </SurrounderDiv>
  );
};

export default PhotoContent;
